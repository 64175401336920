import React from 'react'
import {FormattedMessage} from 'react-intl'
import Content from '../layout/Content'

class ContactThankyou extends React.Component {

   
    render(){
        return(
            <Content>
                
                
                <section className="m_section m_section-services-2">
                    <div className="m_inner">
                        <div className="m_column m_column-12">
                            <h1 className="m_heading">
                                <FormattedMessage 
                                    id="thankyou-heading-1"
                                    defaultMessage="Message Sent"
                                />                                    
                            </h1>
                            <p style={{textAlign: 'center' }}>
                                <FormattedMessage 
                                    id="thankyou-text"
                                    defaultMessage="Thank you for contacting us. A consultant will be in touch with you shortly."
                                /> 
                            </p>
                        </div>
                    </div>
                </section>
            </Content>
        )
    }
}

export default ContactThankyou