import React from 'react'
import {
    Field, reduxForm
} from 'redux-form'
import {connect} from 'react-redux'
import {
    sendContactEmail,
    resetSendContactEmail
} from'../../actions'
import {FormattedMessage} from 'react-intl'
import history from '../../history'

class ContactForm extends React.Component {

    state= {
        button: 'Send'
    }

    componentDidUpdate(){
        if(this.props.sendState.errors){
            setTimeout(() => {
                this.props.resetSendContactEmail()
            }, 7000)
        }
    }

    componentWillUnmount(){
        this.props.resetSendContactEmail()
    }

    renderErrors = () => {
        if(this.props.sendState.errors){
            return Object.values(this.props.sendState.errors).map((item, index) => {                
                return (
                    <div className="_error-group">
                        <div className="_error-message" key={index}>{item[0]}</div>
                    </div>
                )
            })
        }
    }

    renderError({error, touched}){
		if(error && touched){
			return(
                <small className="_error-message" role="alert">
                    {error}
                </small>
			)
		}
	}

    renderInput = ({input, label, meta, type}) => {
        return(
            <div className={`m_input-element ${meta.error && meta.touched ? 'm_error' : ''}`}>
                <label>{label}</label>
                <input {...input} type={type} autoComplete="off" />
                {this.renderError(meta)}
            </div>
        )
    }

    renderSelect = ({input, label, meta, children }) => {
        return (
            <div className={`m_input-element ${meta.error && meta.touched ? 'm_error' : ''}`}>
                <label>{label}</label>
                <select {...input}>
                    {children}
                </select>
                {this.renderError(meta)}
            </div>
        )
    }

    renderTextArea = ({input, label, meta}) => {
        return (
            <div className={`m_input-element ${meta.error && meta.touched ? 'm_error' : ''}`}>
                <label>{label}</label>
                <textarea {...input} rows="3"></textarea>
                {this.renderError(meta)}
            </div>
        )
    }

    onSubmit = formValues => {
        this.setState({
            button: 'Sending'
        })
        this.props.sendContactEmail(formValues)
    }

    render(){

        this.props.sendState.success && history.push('/contact/thankyou')

        if(this.props.sendState.errors){
            if(this.state.button === 'Sending'){
                this.setState({
                    button: 'Send'
                })
            }            
        }

        return(
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <div className="m_input-group">

                    <FormattedMessage 
                        id="contact-name-field"
                        defaultMessage="<field>Name</field>"
                        values={{
                            field: msg => <Field name="name" type="text" label={msg} component={this.renderInput} />
                        }}
                    />
                    <FormattedMessage 
                        id="contact-surname-field"
                        defaultMessage="<field>Surname</field>"
                        values={{
                            field: msg => <Field name="surname" type="text" label={msg} component={this.renderInput} />
                        }}
                    />
                    
                </div>
                <div className="m_input-group">
                    <FormattedMessage 
                        id="contact-email-field"
                        defaultMessage="<field>Email address</field>"
                        values={{
                            field: msg => <Field name="email" type="email" label={msg} component={this.renderInput} />
                        }}
                    />
                    <FormattedMessage 
                        id="contact-phone-field"
                        defaultMessage="<field>Phone number</field>"
                        values={{
                            field: msg => <Field name="phone" type="text" label={msg} component={this.renderInput} />
                        }}
                    />
                    
                </div>
                <div className="m_input-group">
                    <FormattedMessage 
                        id="contact-are-you-a-fms-client-field"
                        defaultMessage="<field>Are You An Fms Client</field>"
                        values={{
                            field: msg => (
                                <Field name="select" label={msg} component={this.renderSelect}>                                    
                                    <option selected value="">
                                        {this.props.locale === "en" ? "Please Select" : "Bitte auswählen"}
                                    </option>                   
                                    <option value="yes">
                                        {this.props.locale === "en" ? "Yes" : "Ja"}                                    
                                    </option>
                                    <option value="no">
                                        {this.props.locale === "en" ? "No" : "Nein"}                                    
                                    </option>
                                </Field>
                            )
                        }}
                    />
                    
                </div>
                <div className="m_input-group">
                    <FormattedMessage 
                        id="contact-subject-field"
                        defaultMessage="<field>My Enquiry Is About</field>"
                        values={{
                            field: msg => <Field name="subject" type="text" label={msg} component={this.renderInput} />
                        }}
                    />
                    
                </div>
                <div className="m_input-group">
                    <FormattedMessage 
                        id="contact-message-field"
                        defaultMessage="<field>Message</field>"
                        values={{
                            field: msg => <Field name="message" label={msg} component={this.renderTextArea} />
                        }}
                    />
                    
                </div>

                <div className="m_input-group">
                    <div className="m_input-element">
                        <button className={`m_btn2 ${this.state.button === 'Sending' ? '_sending' : ''}`}>                            
                            <FormattedMessage 
                                id="btn-submit"
                                defaultMessage="Submit"
                            />
                        </button>
                    </div>
                    
                </div>

                {this.renderErrors()}
                
            </form>
        )
    }
}

const validate = formValues => {

    const errors = {}

    if(!formValues.name){
        errors.name = "you must enter your name"
    }

    if(!formValues.surname){
        errors.surname = "you must enter your surname"
    }

    if(!formValues.email){
        errors.email = "you must enter your email address"
    }

    if(!formValues.phone){
        errors.phone = "you must enter your phone number"
    }

    if(!formValues.select){
        errors.select = "you must select an option"
    }

    if(!formValues.subject){
        errors.subject = "you must enter a subject"
    }

    if(!formValues.message){
        errors.message = "you must enter your message"
    }
    
    return errors
}

const mapStateToProps = state => {
    return{
        locale: state.localeState,
        sendState: state.sendContactMailState
    }
}

const connectedComponent = connect(mapStateToProps, {
    sendContactEmail,
    resetSendContactEmail
})(ContactForm)

export default reduxForm({
    form: 'contact',
    validate
})(connectedComponent)