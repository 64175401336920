import React from 'react'
import {FormattedMessage} from 'react-intl'
import Content from '../layout/Content'
import ServicesColumnsFinancial from '../ui/ServicesColumnsFinancial'
import ServicesColumnsLegal from '../ui/ServicesColumnsLegal'
import ServicesColumnsImmigration from '../ui/ServicesColumnsImmigration'
import {Link} from 'react-router-dom'

class Services extends React.Component {

    state = {
        activeTab: 1
    }

    handleTabsSwitch = tab =>{
        setTimeout(() => {
            this.setState({
                activeTab: tab
            })
        }, 200)
    }

    render(){
        return(
            <Content title="Services | Finest Monetary Solutions" description="“A satisfied customer is the best business strategy of all.” Le Boef">
                <section className="m_section m_section-services-1">
                    <div className="m_overlay"></div>
                    <div className="m_inner">
                        <div className="m_column">
                            <div className="n_column-inner ">
                                <p className="m_p">
                                    <FormattedMessage 
                                        id="services-main-message-part-1"
                                        defaultMessage="“<span>A satisfied customer</span> is the best business strategy of all.”"
                                        values={{
                                            span: msg => (
                                                <span>{msg}</span>
                                            )
                                        }}
                                    />
                                </p>
                                <p>
                                    <FormattedMessage 
                                        id="services-main-message-part-2"
                                        defaultMessage="Michael Le Boef"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                
                <section className="m_section m_section-services-2">
                    <div className="m_inner">
                        <div className="m_column m_column-12">
                            <h1 className="m_heading">
                                <FormattedMessage 
                                    id="services-heading-1"
                                    defaultMessage="Our services"
                                />                                    
                            </h1>
                        </div>
                        <div className="m_column m_column-12">
                            <div className="m_tabs">
                                <ul className="m_ul">
                                    <li className="m_li">
                                        <button className={`n_tab-btn ${this.state.activeTab === 1 ? 'm_active' : ''}`} onClick={() => this.handleTabsSwitch(1)}>
                                            <FormattedMessage 
                                                id="services-tab-financial"
                                                defaultMessage="Financial"
                                            />
                                        </button>
                                    </li>
                                    <li className="m_li">
                                        <button className={`n_tab-btn ${this.state.activeTab === 2 ? 'm_active' : ''}`} onClick={() => this.handleTabsSwitch(2)}>
                                            <FormattedMessage 
                                                id="services-tab-legal"
                                                defaultMessage="Legal"
                                            />
                                        </button>
                                    </li>
                                    <li className="m_li">
                                        <button className={`n_tab-btn ${this.state.activeTab === 3 ? 'm_active' : ''}`} onClick={() => this.handleTabsSwitch(3)}>
                                            <FormattedMessage 
                                                id="services-tab-immigration"
                                                defaultMessage="Immigration"
                                            />
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {
                            this.state.activeTab === 1 && (
                                <>
                                    <div className="m_column m_column-12">
                                        <h4>
                                            <FormattedMessage 
                                                id="services-tab-financial"
                                                defaultMessage="Financial"
                                            />
                                        </h4>
                                    </div>
                                    <ServicesColumnsFinancial />
                                    <div className="m_column m_column-12 m_column-btn">
                                        <Link to="/contact" className="m_btn m_btn-big-text">
                                            <FormattedMessage 
                                                id="btn-get-a-free-quote"
                                                defaultMessage="Get a Free Quote"
                                            />
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                        {
                            this.state.activeTab === 2 && (
                                <>
                                    <div className="m_column m_column-12">
                                        <h4>
                                            <FormattedMessage 
                                                id="services-tab-legal"
                                                defaultMessage="Legal"
                                            />
                                        </h4>
                                    </div>
                                    <ServicesColumnsLegal />
                                    <div className="m_column m_column-12 m_column-btn">
                                        <Link to="/contact" className="m_btn m_btn-big-text">
                                            <FormattedMessage 
                                                id="btn-get-a-free-quote"
                                                defaultMessage="Get a Free Quote"
                                            />
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                        {
                            this.state.activeTab === 3 && (
                                <>
                                    <div className="m_column m_column-12">
                                        <h4>
                                            <FormattedMessage 
                                                id="services-tab-immigration"
                                                defaultMessage="Immigration"
                                            />
                                        </h4>
                                    </div>
                                    <ServicesColumnsImmigration />
                                    <div className="m_column m_column-12 m_column-btn">
                                        <Link to="/contact" className="m_btn m_btn-big-text">
                                            <FormattedMessage 
                                                id="btn-get-a-free-quote"
                                                defaultMessage="Get a Free Quote"
                                            />
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                        
                        
                        
                    </div>
                </section>
            </Content>
        )
    }
}

export default Services