import React from 'react'
import {connect} from 'react-redux'
import {
    setLocale
} from '../../actions'
import {FormattedMessage} from 'react-intl'

const LocaleSelect = props => {

    const onChangeLanguage = e => {
        const selectedLocale = e.target.value
        setTimeout(() => {
            props.setLocale(selectedLocale)
        }, 250)
        
    }

    return (
        <div className="m_locale-select">
            <label>
                <FormattedMessage 
                    id={props.locale === 'en' ? 'menu-english' : 'menu-german'}
                    defaultMessage={props.locale === 'en' ? 'English' : 'Deutsch'}
                />
            </label>
            <select onChange={onChangeLanguage}>
                <option>
                    Please select a language
                </option>
                { props.locale !== 'en' && <option value="en">English</option> }
                { props.locale !== 'de' && <option value="de">German</option> }
            </select>
            
        </div>
    )
}

const mapStateToProps = state => {
    return {
        locale: state.localeState
    }
}

export default connect(mapStateToProps, {
    setLocale
})(LocaleSelect)