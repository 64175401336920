import React from 'react'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'

class ServicesColumnsImmigration extends React.Component {
    render(){
        return(
            <>
                <div className="m_column m_column-4 m_services-column">
                    <Link to="/contact" className="m_column-inner">
                        <div className="m_img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88"><title>Artboard-8</title><g id="Pin"><path d="M45.8574,19.0562a1,1,0,0,1-.8574-.99V16H43v2.0664a1,1,0,0,1-.8579.99A4.8328,4.8328,0,0,0,38,23.8311a4.8508,4.8508,0,0,0,2.3452,4.1416l6.2808,3.77A2.8307,2.8307,0,0,1,45.1689,37H42.8311A2.8343,2.8343,0,0,1,40,34.1689V34H38v.1689a4.8338,4.8338,0,0,0,4.1421,4.7749,1,1,0,0,1,.8579.99V42h2V39.9336a1,1,0,0,1,.8574-.99,4.8259,4.8259,0,0,0,1.7979-8.9165l-6.2813-3.77A2.8307,2.8307,0,0,1,42.8311,21h2.3378A2.834,2.834,0,0,1,48,23.8311V24h2v-.1689A4.85,4.85,0,0,0,45.8574,19.0562Z" /><path d="M44,10A19,19,0,1,0,63,29,19.0216,19.0216,0,0,0,44,10Zm8,15a1,1,0,0,1-1,1H47a1,1,0,0,1-1-1V23.8311A.8323.8323,0,0,0,45.1689,23H42.8311A.8323.8323,0,0,0,42,23.8311a.846.846,0,0,0,.4028.7119l6.2808,3.769A6.8251,6.8251,0,0,1,47,40.7427V43a1,1,0,0,1-1,1H42a1,1,0,0,1-1-1V40.7432a6.8372,6.8372,0,0,1-5-6.5743V33a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v1.1689A.8323.8323,0,0,0,42.8311,35h2.3378A.8323.8323,0,0,0,46,34.1689a.8453.8453,0,0,0-.4033-.7119l-6.28-3.769A6.8614,6.8614,0,0,1,36,23.8311a6.8353,6.8353,0,0,1,5-6.5743V15a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v2.2573a6.8665,6.8665,0,0,1,5,6.5738Z" /><path d="M69.2686,16.66A27.7079,27.7079,0,0,0,45.4639,1.0327c-.9707-.043-1.9571-.043-2.9278,0A27.7084,27.7084,0,0,0,18.7314,16.66,28.66,28.66,0,0,0,20.77,46.042L43.1763,78.5674a1,1,0,0,0,1.6474,0L67.23,46.042A28.6576,28.6576,0,0,0,69.2686,16.66ZM44,50A21,21,0,1,1,65,29,21.0236,21.0236,0,0,1,44,50Z" /><path d="M53.23,69.89,46.47,79.7a2.9965,2.9965,0,0,1-4.94,0l-6.76-9.81C27.74,71.32,23,74.38,23,78c0,5.05,9.22,9,21,9s21-3.95,21-9C65,74.38,60.26,71.32,53.23,69.89Z" /></g></svg>
                        </div>
                        <h4>
                            <FormattedMessage 
                                id="services-immigration-services"
                                defaultMessage="Immigration & Relocation Services"
                            />
                        </h4>
                        <div className="m_services-overlay">
                            <p className="m_p">
                                <FormattedMessage 
                                    id="services-immigration-services-message"
                                    defaultMessage="Some paragraph here..."
                                />
                            </p>
                            <div className="n_btn-green">
                                <FormattedMessage 
                                    id="btn-get-advice"
                                    defaultMessage="Get Advice"
                                />
                            </div>
                        </div>
                    </Link>
                </div>

                <div className="m_column m_column-4 m_services-column">
                    <Link to="/contact" className="m_column-inner">
                        <div className="m_img">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88"><title>Artboard-39</title><g id="Bank"><path d="M78,38V35H10v3a1.0029,1.0029,0,0,0,1,1H77A1.0029,1.0029,0,0,0,78,38Z" /><path d="M10,77H78V74a1.0029,1.0029,0,0,0-1-1H11a1.0029,1.0029,0,0,0-1,1Z" /><rect x="5" y="79" width="78" height="8" rx="1" ry="1" /><path d="M28,41H14v3a1.0029,1.0029,0,0,0,1,1H27a1.0029,1.0029,0,0,0,1-1Z" /><path d="M51,41H37v3a1.0029,1.0029,0,0,0,1,1H50a1.0029,1.0029,0,0,0,1-1Z" /><path d="M74,41H60v3a1.0029,1.0029,0,0,0,1,1H73a1.0029,1.0029,0,0,0,1-1Z" /><rect x="17" y="47" width="8" height="18" /><rect x="40" y="47" width="8" height="18" /><rect x="63" y="47" width="8" height="18" /><path d="M14,68v3H28V68a1.0029,1.0029,0,0,0-1-1H15A1.0029,1.0029,0,0,0,14,68Z" /><path d="M37,68v3H51V68a1.0029,1.0029,0,0,0-1-1H38A1.0029,1.0029,0,0,0,37,68Z" /><path d="M60,68v3H74V68a1.0029,1.0029,0,0,0-1-1H61A1.0029,1.0029,0,0,0,60,68Z" /><path d="M44,10a8,8,0,1,0,8,8A8.0092,8.0092,0,0,0,44,10Zm-.5273,6.6182,1.9492.9746A2.8436,2.8436,0,0,1,45,22.8549V23a1,1,0,0,1-2,0v-.1451a2.8484,2.8484,0,0,1-2-2.7094,1,1,0,0,1,2,0A.8561.8561,0,0,0,43.8545,21h.291a.854.854,0,0,0,.3818-1.6182l-1.9492-.9746A2.8436,2.8436,0,0,1,43,13.1451V13a1,1,0,0,1,2,0v.1451a2.8484,2.8484,0,0,1,2,2.7094,1,1,0,1,1-2,0A.8561.8561,0,0,0,44.1455,15h-.291a.854.854,0,0,0-.3818,1.6182Z" /><path d="M82.4658,21.1152l-38-20a.9953.9953,0,0,0-.9316,0l-38,20A1.0008,1.0008,0,0,0,5,22V32a1,1,0,0,0,1,1H82a1,1,0,0,0,1-1V22A1.0008,1.0008,0,0,0,82.4658,21.1152ZM44,28A10,10,0,1,1,54,18,10.0117,10.0117,0,0,1,44,28Z" /></g></svg>
                        </div>
                        <h4>
                            <FormattedMessage 
                                id="services-property-management"
                                defaultMessage="Property Management"
                            />
                        </h4>
                        <div className="m_services-overlay">
                            <p className="m_p">
                            <FormattedMessage 
                                id="services-property-management-message"
                                defaultMessage="Some paragraph here..."
                            /></p>
                            <div className="n_btn-green">
                                <FormattedMessage 
                                    id="btn-get-advice"
                                    defaultMessage="Get Advice"
                                />
                            </div>
                        </div>
                    </Link>
                </div>
                
            </>
        )
    }
}

export default ServicesColumnsImmigration