import React from 'react'
import NavLinks from './NavLinks'
import {Link} from 'react-router-dom'
import SocialMediaIcons from './SocialMediaIcons'
import {FormattedMessage} from 'react-intl'

class Footer extends React.Component{

    scrollToPosition = hash => {
        setTimeout(() => {
          const element = document.querySelector(hash)
          if(element){
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - 150
          
            window.scrollTo({
                top: topPos, // scroll so that the element is at the top of the view
                behavior: 'smooth' // smooth scroll
            })
          }
          
        },50)    
    }

    render(){
        return(
            <footer className="m_footer">
                <div className="m_inner">
                    <div className="m_column">
                        <h2 className="m_footer-heading">
                            <FormattedMessage
                                id="footer-message-part-1"
                                defaultMessage="Your most reliable financial partner"
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id="footer-message-part-2"
                                defaultMessage="established in 2006"
                            />
                        </p>
                    </div>
                    <div className="m_column">
                        <p className="m_p">
                            <FormattedMessage
                                id="footer-quick-links"
                                defaultMessage="Quick Links"
                            />
                        </p>
                        <ul className="m_footer-ul">
                            <NavLinks />
                        </ul>
                    </div>
                    <div className="m_column">
                        <p className="m_p">
                            <FormattedMessage
                                id="footer-our-products"
                                defaultMessage="Our Products"
                            />
                        </p>
                        <ul className="m_footer-ul">
                            <li className="m_li">
                                <Link to="/products" onClick={() => this.scrollToPosition("#private-banking")}>
                                    <FormattedMessage
                                        id="footer-private-banking"
                                        defaultMessage="Private Banking"
                                    />
                                </Link>
                            </li>
                            <li className="m_li">
                                <Link to="/products" onClick={() => this.scrollToPosition("#investments")}>
                                    <FormattedMessage
                                        id="footer-investments"
                                        defaultMessage="Investments"
                                    />
                                </Link>
                            </li>
                            <li className="m_li">
                                <Link to="/products" onClick={() => this.scrollToPosition("#long-term-risk-insurance")}>
                                    <FormattedMessage
                                        id="footer-long-term-risk-insurance"
                                        defaultMessage="Long-Term risk insurance"
                                    />
                                </Link>
                            </li>
                            <li className="m_li">
                                <Link to="/products" onClick={() => this.scrollToPosition("#short-term-insurance")}>
                                    <FormattedMessage
                                        id="footer-short-term-insurance"
                                        defaultMessage="Short-Term insurance"
                                    />
                                </Link>
                            </li>
                            <li className="m_li">
                                <Link to="/products" onClick={() => this.scrollToPosition("#foreign-exchange")}>
                                    <FormattedMessage
                                        id="footer-foreign-exchange"
                                        defaultMessage="Foreign Exchange"
                                    />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="m_column">
                        <p className="m_p">
                            <FormattedMessage
                                id="footer-corp-cum"
                                defaultMessage="Corporate Communication"
                            />
                        </p>
                        <ul className="m_footer-ul">
                            <li className="m_li">
                                <a href="/pdfs/FMS-Complaints-Policy.pdf" download="FMS Complaints Policy">
                                    <FormattedMessage
                                        id="footer-complaints-policy"
                                        defaultMessage="Complaints Policy"
                                    />
                                </a>
                            </li>
                            <li className="m_li">
                                <a href="/pdfs/MFS-Conflict-of-Interest-Management-Policy.pdf" download="FMS Conflict of Interest Management Policy">
                                    <FormattedMessage
                                        id="footer-conflict-of-interest-management-policy"
                                        defaultMessage="Conflict of Interest Management Policy"
                                        values={{
                                            br: () => <br />
                                        }}
                                    />
                                </a>
                            </li>
                            <li className="m_li">
                                <a href="/pdfs/FMS-TCF-Statement.pdf" download="FMS TCF Statement">
                                    <FormattedMessage
                                        id="footer-tcf-statement"
                                        defaultMessage="TCF Statement"
                                    />
                                </a>
                            </li>
                            <li className="m_li">
                                <a href="/pdfs/FMS-PAIA-Manual.pdf" download="FMS PAIA Manual">
                                    <FormattedMessage
                                        id="footer-paia-manual"
                                        defaultMessage="PAIA Manual"
                                    />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="m_inner">
                    <div className="m_column">
                        <ul className="m_sm-ul">
                            <SocialMediaIcons />
                        </ul>
                    </div>
                </div>
                <div className="m_inner">
                    <div className="m_column">
                        <p>
                            <FormattedMessage
                                id="footer-address"
                                defaultMessage="<span>Address:</span> <a> 1st Floor, The West Peak, 13 Lourensford Road, Somerset West, 7130​</a>"
                                values={{
                                    span: msg => (
                                        <span>{msg}</span>
                                    ),
                                    a: msg => (
                                        <a href="https://goo.gl/maps/jgq8DYEPgRMbCDCR8" target="_blank" rel="noreferrer"> {msg}</a>
                                    )
                                }}
                            />
                        </p>
                    </div>
                    <div className="m_column">
                        <p>
                            <FormattedMessage 
                                id="footer-phone"
                                defaultMessasge="<span>phone:</span> <a1> +27 21 851 7955</a1> / <a2>+27 21 851 8909</a2>"
                                values={{
                                    span: msg => (
                                        <span>{msg}</span>
                                    ),
                                    a1: msg => (
                                        <a href="tel:+27218517955">{msg}</a>
                                    ),
                                    a2: msg => (
                                        <a href="tel:+27218518909">{msg}</a>
                                    )
                                }}
                            />
                        </p>
                    </div>
                    <div className="m_column">
                        <p>
                            <FormattedMessage
                                id="footer-email"
                                defaultMessage="<span>email:</span> <a1> gisela@fm-s.co.za</a1> / <a2> lyle@fm-s.co.za</a2>"
                                values={{
                                    span: msg => (
                                        <span>{msg}</span>
                                    ),
                                    a1: msg => (
                                        <a href="mailto:gisela@fm-s.co.za">{msg}</a>
                                    ),
                                    a2: msg => (
                                        <a href="mailto:lyle@fm-s.co.za">{msg}</a>
                                    )
                                }}
                            />
                        </p>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer