import React from 'react'
import {FormattedMessage} from 'react-intl'
import img1 from '../../assets/img/fms-48.jpg'
import {Link} from 'react-router-dom'
import InfoColumns from '../ui/InfoColumns'
import mtt1 from '../../assets/img/mtt1.jpg'
import mtt2 from '../../assets/img/mtt2.jpg'
import mtt4 from '../../assets/img/mtt4.jpg'
import Content from '../layout/Content'
import bgPattern from '../../assets/img/Group 3109.png'

class Home extends React.Component {

    scrollToPosition = hash => {
        setTimeout(() => {
          const element = document.querySelector(hash)
          if(element){
            const topPos = element.getBoundingClientRect().top + window.pageYOffset - 150
          
            window.scrollTo({
                top: topPos, // scroll so that the element is at the top of the view
                behavior: 'smooth' // smooth scroll
            })
          }
          
        },50)    
    }

    render(){
        return(
            <Content title="Home | Finest Monetary Solutions" description="Not just your financial partner, but committed to provide you with excellent service and the best financial advice available.">
                <section className="m_section m_section-home-1">
                    <div className="m_overlay"></div>
                    <div className="m_inner">
                        <div className="m_column">
                            <div className="n_column-inner m_heading">
                                <p className="m_p">
                                    <FormattedMessage 
                                        id="home-main-message"
                                        defaultMessage="We offer banking, forex, investments and insurance services."
                                    />
                                </p>
                                <p>GISELA NIEMANN</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="m_section m_section-home-2">
                    <div className="m_inner">
                        <div className="m_column m_column-6">
                            <img src={img1} alt="" />
                        </div>
                        <div className="m_column m_column-6">
                            <h1>
                                <FormattedMessage 
                                    id="home-heading-1"
                                    defaultMessage="Our dedication to service is unrivalled"
                                />
                            </h1>
                            <p className="m_p ">
                            <FormattedMessage
                                id="home-par-1"
                                defaultMessage="FMS, not just your financial partner, but committed to providing you with excellent service and the best financial advice available. FMS gives clients the best possible financial solutions upon relocation to South Africa as permanent or non-permanent residents. The company has grown substantially since its initial inception and continues to attract clients from all over the world. Excellent client relations, good communication skills and the best advice and financial planning <strong>backed up by superb service at all levels</strong>."
                                values={{
                                    strong: msg => (
                                        <strong>{msg}</strong>
                                    )
                                }}
                            />
                            </p>
                        </div>
                    </div>
                    <div className="m_background-pattern-img">
                        <img src={bgPattern} alt="" />
                    </div>
                </section>
                <section className="m_section m_section-home-3">
                    <div className="m_inner">
                        <InfoColumns />
                        <div className="m_column m_column-12">
                            <Link to="/contact" className="m_btn m_btn-big-text">
                                <FormattedMessage 
                                    id="btn-get-a-free-quote"
                                    defaultMessage="Get a Free Quote"
                                />
                            </Link>
                        </div>
                    </div>
                </section>
                <section className="m_section m_section-home-4">
                    <div className="m_inner">
                        <div className="m_column m_column-12">
                            <h2 className="m_heading">
                                <FormattedMessage 
                                    id="home-heading-2"
                                    defaultMessage="Meet the team"
                                />
                            </h2>
                        </div>
                        <div className="m_column m_column-4" onClick={() => this.scrollToPosition("#gisela-nieman")}>
                            <Link to="/about" className="m_link">
                                <img src={mtt1} alt="" />
                                <h4 className="m_p">
                                    <FormattedMessage 
                                        id="team-gisela-nieman"
                                        defaultMessage="Gisela Niemann"
                                    />
                                </h4>
                            </Link>
                        </div>
                        <div className="m_column m_column-4" onClick={() => this.scrollToPosition("#lyle-maree")}>
                            <Link to="/about" className="m_link">
                                <img src={mtt2} alt="" />
                                <h4 className="m_p">
                                    <FormattedMessage 
                                        id="team-lyle-maree"
                                        defaultMessage="Lyle Maree"
                                    />
                                </h4>
                            </Link>
                        </div>
                        
                        <div className="m_column m_column-4" onClick={() => this.scrollToPosition("#antje-braun")}>
                            <Link to="/about" className="m_link">
                                <img src={mtt4} alt="" />
                                <h4 className="m_p">
                                    <FormattedMessage 
                                        id="team-antje-braun"
                                        defaultMessage="Antje Braun"
                                    />
                                </h4>
                            </Link>
                        </div>
                    </div>
                </section>
            </Content>
        )
    }
}

export default Home