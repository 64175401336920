import React from 'react'
import {
    Router,
    Switch,
    Route
} from 'react-router-dom'
import {IntlProvider} from 'react-intl'
import history from '../history'
// import Popup from './ui/Popup'

//components
import Layout from './layout/Layout'
import Home from './pages/Home'
import About from './pages/About'
import Services from './pages/Services'
import Products from './pages/Products'
import Contact from './pages/Contact'
import ContactThankyou from './pages/ContactThankyou'

import {connect} from 'react-redux'

class App extends React.Component {

    translations = {
        en: require('../translations/translations-en.json'),
        de: require('../translations/translations-de.json')
    }

    render(){
        return(
            <IntlProvider 
                messages={this.translations[this.props.locale]} 
                locale={this.props.locale} 
                defaultLocale="en"
            >
                {/* <Popup>
                    <p className='m_p '>
                        We are moving offices on the 28th and 29th of April. Our New Address is going to be: 1st Floor, The West Peak, 13 Lourensford Road, Somerset West.
                    </p>
                </Popup> */}
                <Router history={history}>
                    <Layout>                        
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route exact path="/about" component={About} />
                            <Route exact path="/services" component={Services} />
                            <Route exact path="/products" component={Products} />
                            <Route exact path="/contact" component={Contact} />
                            <Route exact path="/contact/thankyou" component={ContactThankyou} />
                        </Switch>                   
                    </Layout>
                </Router>
            </IntlProvider>
        )
    }
}

const mapStateToProps = state => {
    return {
        locale: state.localeState
    }
}

export default connect(mapStateToProps)(App)